import React from 'react';
import { Link } from 'react-router-dom';

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const styles = (theme) => ({
})

function WhereToStart( { classes, theme } ) {
  return (
    <>
      <Typography variant="h4" style={{ marginBottom: theme.spacing(2) }}>
        Where To Start
      </Typography>


      <Box style={{ clear: 'both' }}>
        <Divider style={{
          marginTop: theme.spacing(0),
          marginBottom: theme.spacing(0),
          backgroundColor:"white"
          }}
        />
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Box style={{
              paddingTop:theme.spacing(2),
              paddingBottom:theme.spacing(2),
            }}
          >
            <img src="/beach.png" alt="Byrne" style={{ float:'left', marginRight: theme.spacing(2.5), width: theme.spacing(12), marginBottom: theme.spacing(2), }} />
            <Typography variant="h6">Welcome to STEPS</Typography>
          </Box>
        </Link>
      </Box>
      <Box style={{ clear: 'both' }}>
        <Divider style={{
          marginTop: theme.spacing(0),
          marginBottom: theme.spacing(0),
          backgroundColor:"white"
          }}
        />
        <a href="https://headlesswp.stepscourse.org/wp-content/uploads/2021/07/STEPS_Essentials.pdf" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
          <Box style={{
              paddingTop:theme.spacing(2),
              paddingBottom:theme.spacing(2),
            }}
          >
            <img src="/standingman.png" alt="Byrne" style={{ float:'left', marginRight: theme.spacing(2.5), width: theme.spacing(12), marginBottom: theme.spacing(2), }} />
            <Typography variant="h6">Essential guide to running STEPS</Typography>
          </Box>
        </a>
      </Box>
      <Box style={{ clear: 'both' }}>
        <Divider style={{
          marginTop: theme.spacing(0),
          marginBottom: theme.spacing(0),
          backgroundColor:"white"
          }}
        />
        <Link to="/overview/frequently-asked-questions" style={{ textDecoration: 'none' }}>
          <Box style={{
              paddingTop:theme.spacing(2),
              paddingBottom:theme.spacing(2),
            }}
          >
            <img src="/hills.png" alt="Byrne" style={{ float:'left', marginRight: theme.spacing(2.5), width: theme.spacing(12), marginBottom: theme.spacing(2), }} />
            <Typography variant="h6">FAQs</Typography>
          </Box>
        </Link>
      </Box>
    </>
  )
}

export default withStyles(styles, { withTheme: true })(WhereToStart)
