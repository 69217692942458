import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import classNames from "classnames";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import ScrollTop from '../../atoms/ScrollTop';
import Copyright from '../../atoms/Copyright';

const styles = (theme) => ({
  footer: {
    position: "relative",
    backgroundColor: theme.palette.common.white,
    zIndex: theme.zIndex.drawer + 1,
    borderTop: '1px Solid #ccc',
    color: theme.palette.primary.main,
    justifyContent: "space-between",
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
  },
});

function Footer(props) {
  const { theme, classes } = props;

  const [footerState, setFooterState] = useState({
    loading: true,
    footer: [
      {
        acf: {
          footer: {
            copyright: "",
            social_links: [
              {
                "id": "1",
                "social_link": "",
                "url": "",
              },
            ],
          },
        },
      },
    ],
  });
  const loadFooter = useCallback(() => {
    setFooterState(prevState => {
      return {...prevState, loading: true}
    });
    const apiUrl = `${process.env.REACT_APP_API_URL}//wp-json/wp/v2/header_and_footer`;
    axios.get(apiUrl)
      .then((response) => {
        const footerData = response.data;
        setFooterState({ loading: false, footer: footerData });
      })
      .catch(() => {
        console.log("No data loaded!")
      });
  }, []);

  useEffect(() => {
    loadFooter();
  }, [loadFooter]);

  return (
    <Grid container direction="row-reverse" className={classNames(classes.footer)}>

      <Grid item xs={12} sm={3} style={{ textAlign:"right", marginBottom: theme.spacing(3) }}>
        <ScrollTop {...props}>
          <Typography variant="body2" style={{ lineHeight:"1.75rem", cursor: "pointer" }}><KeyboardArrowUpIcon /> Back to top</Typography>
        </ScrollTop>
      </Grid>
      <Grid item xs={12} sm={9}>
        <Typography variant="body2" component="div">
          <Copyright copyright={footerState.footer[0].acf.footer.copyright} />
        </Typography>
      </Grid>

    </Grid>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Footer);
