import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../../organisms/Firebase';

import { withStyles } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  stepLink: {
    textDecoration: "none",
  },
  bigNumber: {
    fontSize: "24rem",
    color: theme.palette.secondary.main,
    float:'right',
    marginTop:-theme.spacing(10),
    marginRight:-theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      fontSize: "32rem",
    },
  },
})

class YourCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateOfIntroduction: '',
      dateOfWeekOne: '',
      dateOfWeekFive: '',
      date: '',
      title: '',
      stepLink: '',
    };
  }

  componentDidMount() {
    this.listener = this.props.firebase.auth.onAuthStateChanged((authUser) => {
      const getDates = async () => {
        await this.props.firebase.user(authUser.uid).once('value').then( (snapshot) => {
          if (snapshot.exists()){
            const data = snapshot.val();
            this.setState({
              dateOfIntroduction: data.dateOfIntroduction,
              dateOfWeekOne: data.dateOfWeekOne,
              dateOfWeekFive: data.dateOfWeekFive
            })
          }
        })
        this.stepsWeek(this.state.dateOfIntroduction, this.state.dateOfWeekOne, this.state.dateOfWeekFive)
      }
      if (authUser) {
        getDates();
      }
    })
  }

  componentWillUnmount() {
    this.listener();
  }

  stepsWeek(dateIntro, dateOne, dateFive) {
    console.log( `${dateIntro}, ${dateOne}, ${dateFive}` );
    const today = new Date();
    const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    console.log(date);
    if ( dateIntro >= date ) this.setState({ date: dateIntro, title: 'Introduction', emailLink: 'https://google.com' });
  }

  render() {
    const { theme, classes } = this.props;

    return (
      <>
        <Box style={{ position:'absolute', top:0, left:0, bottom:0, right:0, zIndex:0, overflow:'hidden' }}>
          <Typography className={classes.bigNumber} variant="h1">
            01
          </Typography>
        </Box>
        <Typography variant="h4" style={{ marginBottom: theme.spacing(6), zIndex:1 }}>
          Your STEPS Course
        </Typography>
        <Box style={{ flexGrow: 2, zIndex:1 }}>
          {this.state.date}<br/>
          <Typography variant="h3" style={{ marginBottom: theme.spacing(2) }}>
            <Link className={classes.stepLink} to={this.state.stepLink}>
              Next STEP {this.state.title}<br/>
              Step 1: Recognition
            </Link>
          </Typography>
        </Box>
        <Box style={{ zIndex:1 }}>
          STEPS tips: Have you sent the email?
        </Box>
      </>
    );
  }
}

export default withFirebase(withStyles(styles, { withTheme: true })(YourCourse))
