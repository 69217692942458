import { withStyles } from "@material-ui/core";
import DanmarkWoff from "./fonts/Danmark_LightWEB.woff";

const danmark = {
  fontFamily: "Danmark",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local("Danmark"),
    url(${DanmarkWoff}) format("woff")
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

const styles = theme => ({
  "@global": {
    "html":{
      backgroundColor: theme.palette.common.white,
    },
    /* Typography
    ===========================================*/
    "@font-face": [danmark],
    body: {
      fontSize: theme.typography.body1.fontSize,
    },
    ".standFirst": {
      "& em": {
        fontFamily: 'baskerville-urw, serif',
        textDecoration: "underline",
      },
      "& p": {
        marginBottom: 0,
      },
    },
    "a": {
      color: theme.palette.primary.main,

      ".blue &": {
        color: theme.palette.common.white,
      },
    },
    "a.active": {
      textDecoration: "underline",
    },
    "a.root.overview": {
      textDecoration: "underline",
    },
    'a.plainLink': {
      textDecoration: 'none',
      color:'#000'
    },
    'p.logout':{
      '&:hover':{
        cursor:"pointer",
        textDecoration: "underline",
      },
    },
    "div p:first-of-type": {
      marginTop: 0,
    },
    ".MuiGrid-item div:last-child > p": {
      marginBottom:0,
    },
    ".MuiGrid-item div div:last-child p": {
      marginBottom:0,
    },
    /* Fade
    =========================================*/
    ".fadeWrapper":{
      position:"absolute",
      top: "3.5rem",
      width:"100%",
      height:"120%",
      backgroundColor: theme.palette.common.white,
      zIndex: theme.zIndex.tooltip,
      [theme.breakpoints.up('sm')]: {
        top:"4rem",
      },
      [theme.breakpoints.up('lg')]: {
        backgroundColor:theme.palette.primary.main,
        "&.stories, &.home":{
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    ".fadeWrapper.showContent":{
      opacity: 0,
      visibility: "hidden",
      transition: "opacity 0.4s ease-in-out, visibility 0.4s cubic-bezier(1.000, 0.000, 1.000, 0.000)"
    },
    ".fadeWrapper.hideContent":{
      opacity: 1,
      visibility: "visible",
      transition: "opacity 0s ease-in-out, visibility 0s",
    },
    /* Section Overviews
    =========================================*/
    ".blue": {
      backgroundColor: "#153A7C",
      color: theme.palette.common.white,
    },
  },
});

function globalStyles() {
  return null;
}

export default withStyles(styles, { withTheme: true })(globalStyles);
