import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import classNames from "classnames";
import { withAuthorization } from '../Session';

import ProfileSnapshot from '../../molecules/ProfileSnapshot';
import DashboardMenu from '../../molecules/DashboardMenu';
import YourCourse from '../../molecules/YourCourse';
import WhereToStart from '../../molecules/WhereToStart';
import UpcomingEvents from '../../molecules/UpcomingEvents';
import Footer from '../../organisms/Footer';
import SiteLogo from '../../atoms/SiteLogo';

import { withStyles } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const drawerWidth = 240;
const drawerWidthSm = 360;
const drawerWidthMd = 320;

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidthMd,
      flexShrink: 0,
    },
  },
  drawer__toolbar: {
    justifyContent: "flex-end",
    [theme.breakpoints.up('md')]: {
      justifyContent: "space-between",
    },

    "& button": {
      marginRight:`-${theme.spacing(2)}px`,
      color:theme.palette.primary.main,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: drawerWidthSm,
    },
    [theme.breakpoints.up('md')]: {
      width: drawerWidthMd,
    },
  },
  drawer__items: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(3)}px`,
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(8)}px ${theme.spacing(6)}px ${theme.spacing(5)}px ${theme.spacing(6)}px`,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 'calc(100% - 64px)',
  },
  content: {
    minHeight: `calc(100vh - ${theme.spacing(8)}px)`,
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(4)}px ${theme.spacing(3)}px`,
    },
    [theme.breakpoints.up('md')]: {
      padding: `${theme.spacing(8)}px ${theme.spacing(12)}px ${theme.spacing(8)}px ${theme.spacing(12)}px`,
    },
  },
  paperYC: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: theme.spacing(44),
    position: 'relative',
  },
  paperWTS: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    height: "100%",
    marginBottom: theme.spacing(4),
    "& a": {
      color: theme.palette.common.white,
    }
  }
})

function Dashboard(props) {

  const { theme, classes, mobileOpen, onClose, onClick } = props;

  const isMed = useMediaQuery(theme.breakpoints.up('md'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));

  const [siteLogoState, setSiteLogoState] = useState({
    loading: true,
    siteLogo: [
      {
        acf: {
          header: {
            site_logo: {
              title: "Site Logo",
              url: "",
            },
            site_logo_alt: {
              title: "Site Logo Alt",
              url: "",
            },
          },
        },
      },
    ],
  });

  const loadSiteLogo = useCallback(() => {
    setSiteLogoState(prevState => {
      return {...prevState, loading: true}
     });
    const apiUrl = `${process.env.REACT_APP_API_URL}//wp-json/wp/v2/header_and_footer`;
    axios.get(apiUrl)
      .then((response) => {
        const siteLogoData = response.data;
        setSiteLogoState({ loading: false, siteLogo: siteLogoData });
      })
      .catch(() => {
        console.log("No data loaded!")
      });
  }, []);

  useEffect(() => {
    loadSiteLogo();
  }, [loadSiteLogo]);

  const drawer = (
    <Box style={{ height:'100%' }}>
      <Toolbar className={classes.drawer__toolbar}>
        <Hidden smDown>
          <SiteLogo siteLogo={siteLogoState.siteLogo[0].acf.header.site_logo} noLink />
        </Hidden>
        <Hidden mdUp>
          <IconButton onClick={onClick}>
            <CloseIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Typography variant="body1" component="div" className={classes.drawer__items}>
        <DashboardMenu onClick={onClick} />
        <div style={ isLg ? { marginBottom: "8px" } : { marginBottom: "4px" }}>
          <ProfileSnapshot onClick={onClick} />
        </div>
      </Typography>
    </Box>
  );

  return (
    <Box className={classes.root}>
      <nav className={classes.drawer} aria-label="Dashboard Account">
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor="right"
            open={mobileOpen}
            onClose={onClose}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Drawer classes={{paper: classes.drawerPaper}} variant="permanent" open>
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

      <main style={{ width: "100%", backgroundColor: "#fafafa", }}>
        <Box className={classes.content}>
          <Box className={classes.toolbar} />
          <Grid container spacing={ isMed ? 5 : 2 }>
            <Grid item xs={12} lg={12}>
              <Paper square elevation={1} className={classNames(classes.paperYC)}>
                <YourCourse />
              </Paper>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Paper square elevation={1} className={classNames(classes.paperWTS)}>
                <WhereToStart />
              </Paper>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Paper square elevation={1} style={{ padding: theme.spacing(3) }}>
                <UpcomingEvents />
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Footer />
      </main>

    </Box>
  )
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(withStyles(styles, { withTheme: true })(Dashboard));
